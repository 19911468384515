import React, {useEffect, useState} from "react";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import OverviewActionContainer from "./OverviewActionContainer";
import OverviewTableContainer from "./OverviewTableContainer";
import OverviewMenuFilterContainer from "./OverviewMenuFilterContainer";
import OverviewTableFooter from "./OverviewTableFooter";
import "../../styles/styleCaseOverview.css";
import {useCustomHookContext} from "../customHooks/useCustomHookContext";

const link = document.createElement("link");
link.href = "https://fonts.googleapis.com/css2?family=Overpass:wght@400&display=swap";

document.head.appendChild(link);

// Overview component definition
const Overview = () => {
  const navigate = useNavigate(); // Hook for navigation
  const location = useLocation(); // Hook for getting the state location
  const [allCases, setAllCases] = useState([]); // State to hold all cases
  const [defaultAllCases, setDefaultAllCases] = useState([]); // State for default cases before filters
  const [defaultTotalElements, setDefaultTotalElements] = useState(0); // State for total elements of default cases
  const [filteredCases, setFilteredCases] = useState([]); // State for cases after filtering
  const [rowsPerPage, setRowsPerPage] = useState(location.state?.rowsPerPage || 20); // State for number of rows per page
  const [currentPage, setCurrentPage] = useState(location.state?.currentPage || 0); // State for current page number
  const [totalElements, setTotalElements] = useState(0); // State for total number of elements
  const [selectedRows, setSelectedRows] = useState([]); // State to manage selected rows
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  // Call custom hooks on startup
  const {statusOptions, categoryOptions, labelOptions, activeAssigneeOptions, allUserOptions} = useCustomHookContext();

  // State to store the request body
  const [requestBody, setRequestBody] = useState({
    filter: location.state?.filter || [],
    page: currentPage,
    pageSize: rowsPerPage,
    sortOption: location.state?.sortOptionAscending || "",
  });

  // Simulate progress bar
  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (!loading || oldProgress >= 100) {
            clearInterval(timer);
            setLoading(false);
            return 100;
          }
          //const diff = Math.random() * 10;
          return Math.min(oldProgress + 10, 100);
        });
      }, 120);

      return () => {
        clearInterval(timer);
      };
    }
  }, [loading]);

  // Fetch cases from the API
  useEffect(() => {
    const getAllCases = async () => {
      setLoading(true); // Set loading to true when starting to fetch data
      try {
        const response = await axios.post("/overview", requestBody, {
          headers: {
            withCredentials: true,
          },
        });
        if (response.status === 200) {
          const data = response.data.content; // Get cases data from response
          const total = response.data.page.totalElements;

          if (filteredCases.length === 0) {
            // If no filters are applied, update default cases
            setDefaultAllCases(data);
            setDefaultTotalElements(total);
          }

          // Update state based on current data and filters
          setAllCases(data);
          setTotalElements(total);
        }
      } catch (error) {
        console.error("Error fetching table rows:", error); // Log error if fetching fails
      } finally {
        setLoading(false); // Set loading to false when fetch is done
      }
    };

    getAllCases(); // Fetch cases when dependencies change
  }, [currentPage, rowsPerPage, refreshTrigger]); // Dependencies for effect

  // Handler to receive requestBody from OverviewMenuFilterContainer
  const handleFilterRequestBody = (newRequestBody) => {
    setRequestBody(newRequestBody); // Update the requestBody state with the new filter
  };

  // Handle filter change
  const handleFilterChange = (filteredCases, length) => {
    setFilteredCases(filteredCases); // Update state with filtered cases
    setCurrentPage(0); // Reset to first page when filters change
    setTotalElements(length); // Set total elements based on filtered cases
    setAllCases(filteredCases); // Set displayed cases to filtered cases
  };

  // Clear filters
  const handleClearFilters = () => {
    setFilteredCases([]); // Reset filtered cases
    setAllCases(defaultAllCases); // Reset to default cases
    setTotalElements(defaultTotalElements); // Update total number of elements based on default data
    setCurrentPage(0); // Reset to first page
    setRowsPerPage(rowsPerPage); // Reset to default rows per page
    setRefreshTrigger(true);

    // Reset requestBody to its initial default values
    setRequestBody({
      filter: [], // Clear filters
      page: 0, // Reset to first page
      pageSize: 20, // Reset to default page size
    });

    // Clear filters and reset location.state
    navigate("/overviewTable", {
      state: { // Reset filters and pagination in location.state
        filter: [], // Clear any filters
        currentPage: 0, // Reset to first page
        rowsPerPage: 20, // Default page size
      },
    });
  };

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage); // Update current page
    setRequestBody((prevRequestBody) => ({
      ...prevRequestBody,
      page: newPage,  // Update the page in requestBody
    }));
  };

// Handle changes to the number of rows per page
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10); // Parse new rows per page
    setRowsPerPage(newRowsPerPage); // Update state with new rows per page
    setCurrentPage(0); // Reset to first page
    setRequestBody((prevRequestBody) => ({
      ...prevRequestBody,
      pageSize: newRowsPerPage, // Update pageSize in requestBody
      page: 0, // Reset page to 0 when rows per page changes
    }));
  };


  // Handle row click to navigate to case details
  const handleRowClick = (params) => {
    navigate("/caseDetails", { state: { rowData: params.row } }); // Navigate to case details page
  };

  const handleSelectedRowsChange = (rows) => {
    setSelectedRows(rows);
  };

  const handleFilterApply = () => {
    setRefreshTrigger((prev) => !prev); // Toggle to trigger re-fetch
  };

  // Determine the count for pagination
  const count = totalElements;

  return (
    <div className="divOverviewContainer">
      {/* Filter Menu Component */}
      <OverviewMenuFilterContainer
        onFilterChange={handleFilterChange} // Pass filter change handler
        onClearFilters={handleClearFilters} // Pass clear filters handler
        onFilterRequestBody={handleFilterRequestBody} // Pass requestBody to the parent
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        requestBody={requestBody}
        statusOptions={statusOptions}
        categoryOptions={categoryOptions}
        labelOptions={labelOptions}
        allUserOptions={allUserOptions}
      />
      {/* Action Container Component */}
      <OverviewActionContainer selectedRows={selectedRows} onFilterApply={handleFilterApply} statusOptions={statusOptions} categoryOptions={categoryOptions} labelOptions={labelOptions} activeAssigneeOptions={activeAssigneeOptions} />
      {/* Table Container Component */}
      <OverviewTableContainer
        allCases={allCases} // Show filtered cases or all cases
        onRowClick={handleRowClick} // Pass row click handler
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        onSelectedRowsChange={handleSelectedRowsChange}
        loading={loading}
        progress={progress}
        requestBody={requestBody}
      />
      {/* Table Footer Component */}
      <OverviewTableFooter
        count={count} // Correctly determine count for pagination
        page={currentPage}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange} // Pass page change handler
        onRowsPerPageChange={handleRowsPerPageChange} // Pass rows per page change handler
      />
    </div>
  );
};

export default Overview; // Export the Overview component
