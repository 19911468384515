import React, { useState, useEffect, useCallback } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, LinearProgress, Typography, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import "../../styles/styleCaseDetailHitSelectionTableContainer.css";

// Main component that handles the selection of DSP and TIP rows, showing search results, and handling state updates
function CaseDetailHitSelectionTableContainer({
  selectedDspRow = [],
  selectedTipRow = [],
  selectedCspRow = [],
  dspSearchResults = [],
  tipSearchResults = [],
  cspSearchResults = [],
  onSelectedRowsChange,
  reset,
  onRowSelect,
  dspLastMatchedDate,
  tipLastMatchedDate,
  cspLastMatchedDate,
  dspReportCreationDate,
  tipReportCreationDate,
  cspReportCreationDate,
  dspSelectedIdentifier,
  tipSelectedIdentifier,
  cspSelectedIdentifier, cspProductCalls,
}) {
  // State to manage selected rows for DSP, initializing with passed selected rows
  const [selectedDSPRows, setSelectedDSPRows] = useState(() =>
    selectedDspRow.map((item) => {
      const match = dspSearchResults.find((result) => result.id === item.id);
      return { ...item, bdsgBlocked: match?.bdsgBlocked || false };
    })
  );

  // State to manage selected rows for TIP, initializing with passed selected rows
  const [selectedTIPRows, setSelectedTIPRows] = useState(() =>
    selectedTipRow.map((item) => {
      const match = tipSearchResults.find((result) => result.id === item.id);
      return { ...item, bdsgBlocked: match?.bdsgBlocked || false };
    })
  );

  // State to manage selected rows for CSP, initializing with passed selected rows
  const [selectedCSPRows, setSelectedCSPRows] = useState(() =>
    selectedCspRow.map((item) => {
      const match = cspSearchResults.find((result) => result.id === item.id);
      return { ...item, bdsgBlocked: match?.bdsgBlocked || false };
    })
  );

  // Loading state and progress bar state
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  // Determine if selection should be disabled based on report creation and last matched dates
  const disableDSPSelection = dspReportCreationDate > dspLastMatchedDate;
  const disableTIPSelection = tipReportCreationDate > tipLastMatchedDate;
  const disableCSPSelection = cspReportCreationDate > cspLastMatchedDate;

  // Date converter
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    /* return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`; */
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  };

  // Simulate loading progress
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (!loading || oldProgress === 100) {
            clearInterval(interval);
            setLoading(false);
            return 100;
          }
          return Math.min(oldProgress + 10, 100);
        });
      }, 100);
    }
  }, [loading]);

  // Reset selected rows if the reset flag is true
  useEffect(() => {
    if (reset) {
      setSelectedDSPRows(selectedDspRow);
      setSelectedTIPRows(selectedTipRow);
      setSelectedCSPRows(selectedCspRow);
    }
  }, [reset, selectedDspRow, selectedTipRow]);

  // Update selected rows if search results change to ensure consistency
  useEffect(() => {
    if (dspSearchResults.length > 0) {
      const updatedSelectedDSPRows = selectedDSPRows.filter((row) => dspSearchResults.some((result) => result.id === row.id));
      setSelectedDSPRows(updatedSelectedDSPRows);
    }
    if (tipSearchResults.length > 0) {
      const updatedSelectedTIPRows = selectedTIPRows.filter((row) => tipSearchResults.some((result) => result.id === row.id));
      setSelectedTIPRows(updatedSelectedTIPRows);
    }
    if (cspSearchResults.length > 0) {
      const updatedSelectedCSPRows = selectedCSPRows.filter((row) => cspSearchResults.some((result) => result.id === row.id));
      setSelectedCSPRows(updatedSelectedCSPRows);
    }
  }, [dspSearchResults, tipSearchResults, cspSearchResults]);

  // Handle row selection and deselection, updating state accordingly
  const handleRowSelect = useCallback(
    (event, row, tableType) => {
      let selectedRows, setSelectedRows, disableSelection;

      // Determine the state and handler based on table type (DSP, CSP or TIP)
      if (tableType === "DSP") {
        selectedRows = selectedDSPRows;
        setSelectedRows = setSelectedDSPRows;
        disableSelection = disableDSPSelection;
      } else if (tableType === "TIP") {
        selectedRows = selectedTIPRows;
        setSelectedRows = setSelectedTIPRows;
        disableSelection = disableTIPSelection;
      } else if (tableType === "CSP") {
        selectedRows = selectedCSPRows;
        setSelectedRows = setSelectedCSPRows;
        disableSelection = disableCSPSelection;
      }

      if (disableSelection) return; // Return early if selection is disabled

      // Toggle row selection state
      const isSelected = selectedRows.some((selectedRow) => selectedRow.id === row.id);
      const newSelected = isSelected
        ? selectedRows.filter((selectedRow) => selectedRow.id !== row.id) // Deselect row
        : [...selectedRows, row]; // Select row

      // Update state with new selection
      setSelectedRows(newSelected);

      // Callback to notify parent component of row selection change
      if (onRowSelect) onRowSelect(newSelected, tableType);
    },
    [selectedDSPRows, selectedTIPRows, selectedCSPRows, disableDSPSelection, disableTIPSelection, disableCSPSelection, onRowSelect]
  );

  // Utility function to format date strings
  const parseDateFromInput = (dateString) => {
    if (!dateString || typeof dateString !== "string") return "";

    const [year, month, day] = dateString.split("-");
    return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`;
  };

  // Utility function to format date which is in list format
  const listToDate = (timestamp) => {
    if (!timestamp) return "";

    const [year, month, day] = timestamp;
    return `${String(day).padStart(2, '0')}.${String(month).padStart(2, '0')}.${year}`;
  }

  // Effect to notify parent component of selected rows change
  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange({ DSP: selectedDSPRows, TIP: selectedTIPRows, CSP: selectedCSPRows });
    }
  }, [selectedDSPRows, selectedTIPRows, selectedCSPRows, onSelectedRowsChange]);

  // Set selected rows based on specific identifiers, if provided
  useEffect(() => {
    if (dspSelectedIdentifier) {
      const filteredDSPRows = dspSearchResults.filter((result) => String(result.id) === String(dspSelectedIdentifier));
      setSelectedDSPRows(filteredDSPRows);
    }
    if (tipSelectedIdentifier) {
      const filteredTIPRows = tipSearchResults.filter((result) => String(result.id) === String(tipSelectedIdentifier));
      setSelectedTIPRows(filteredTIPRows);
    }
    if (cspSelectedIdentifier) {
      const filteredCSPRows = cspSearchResults.filter((result) => String(result.id) === String(cspSelectedIdentifier));
      setSelectedCSPRows(filteredCSPRows);
    }
  }, [dspSelectedIdentifier, tipSelectedIdentifier, cspSelectedIdentifier, dspSearchResults, tipSearchResults, cspSearchResults]);

  // Notify parent component when filtered rows change due to identifier
  useEffect(() => {
    const filteredDSP = dspSelectedIdentifier ? dspSearchResults.filter((result) => String(result.id) === String(dspSelectedIdentifier)) : [];
    const filteredTIP = tipSelectedIdentifier ? tipSearchResults.filter((result) => String(result.id) === String(tipSelectedIdentifier)) : [];
    const filteredCSP = cspSelectedIdentifier ? cspSearchResults.filter((result) => String(result.id) === String(cspSelectedIdentifier)) : [];

    if ((filteredDSP.length > 0 || filteredTIP.length > 0 || filteredCSP.length > 0) && onSelectedRowsChange) {
      onSelectedRowsChange({
        DSP: filteredDSP.length > 0 ? filteredDSP : [],
        TIP: filteredTIP.length > 0 ? filteredTIP : [],
        CSP: filteredCSP.length > 0 ? filteredCSP : [],
      });
    }
  }, [dspSearchResults, tipSearchResults, cspSearchResults, dspSelectedIdentifier, tipSelectedIdentifier, cspSelectedIdentifier, onSelectedRowsChange]);

  // Determine which DSP rows to display based on selection
  const dspRowsToDisplay =
    selectedDSPRows.length > 0
      ? selectedDSPRows
      : dspSearchResults.map((result) => ({
          ...result,
          isSelected: false,
        }));

  // Determine which TIP rows to display based on selection
  const tipRowsToDisplay =
    selectedTIPRows.length > 0
      ? selectedTIPRows
      : tipSearchResults.map((result) => ({
          ...result,
          isSelected: false,
        }));

  // Determine which CSP rows to display based on selection
  const cspRowsToDisplay =
    selectedCSPRows.length > 0
      ? selectedCSPRows
      : cspSearchResults.map((result) => ({
          ...result,
          isSelected: false,
        }));

  // Render the component
  return (
    <div className="caseDetailHitSelectionTableContainer">
      {loading ? (
        // Show loading progress bar if still loading
        <div className="loadingContainer">
          <div className="progressBarWrapper">
            <LinearProgress className="progressBar" variant="determinate" value={progress} />
          </div>
          <Typography className="progressText">{`Daten werden geladen ${Math.round(progress)}%`}</Typography>
        </div>
      ) : (
        <>
          {/* Display TIP search results if available */}
          {tipRowsToDisplay.length > 0 && (
            <div className="hitResultsTIP">
              <div className="hlHitProduct">
                TIP
                {tipLastMatchedDate && <span className="lastMatchedDate">(Zuletzt aktualisiert: {formatTimestamp(tipLastMatchedDate)})</span>}
              </div>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead className="tableHitResultsHeader">
                    <TableRow>
                      <TableCell className="hitResultRowCheckBox"></TableCell>
                      <TableCell className="hitResultRowStandard">Vorname</TableCell>
                      <TableCell className="hitResultRowStandard">Nachname</TableCell>
                      <TableCell className="hitResultRowSmaller">Geburtsdatum</TableCell>
                      <TableCell className="hitResultRowStandard">Anschrift</TableCell>
                      <TableCell className="hitResultRowStandard">IDs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tipRowsToDisplay.map((hit) =>
                      hit.bdsgBlocked ? (
                        // Display row as blocked if BDSG is active
                        <TableRow className="hitGesperrt" key={hit.id}>
                          <TableCell colSpan={8}>Anzeige gesperrt durch BDSG</TableCell>
                        </TableRow>
                      ) : (
                        // Render each selectable TIP row
                        <Tooltip title={<div className="toolTipText">Sie können die Auswahl nicht mehr aufheben, da das Dokument bereits erstellt wurde</div>} arrow placement="left" disableHoverListener={!disableTIPSelection} key={hit.id}>
                          <TableRow>
                            <TableCell className="hitResultRowCheckBox">
                              <Checkbox checked={selectedTIPRows.some((selectedRow) => selectedRow.id === hit.id) || hit.isSelected} onChange={(event) => handleRowSelect(event, hit, "TIP")} disabled={disableTIPSelection} />
                            </TableCell>
                            <TableCell className="hitResultRowStandard">{hit.firstName}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.lastName}</TableCell>
                            <TableCell className="hitResultRowSmaller">{parseDateFromInput(hit.birthDate)}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.street} {hit.houseNo}<br/>{hit.zipCode} {hit.city}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.referenceNo?`MainId: ${hit.referenceNo}`:""}<br/>{hit.id?`LiableId: ${hit.id}`:""}</TableCell>
                          </TableRow>
                        </Tooltip>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          {/* Display DSP search results if available */}
          {dspRowsToDisplay.length > 0 && (
            <div className="hitResultsDSP">
              <div className="hlHitProduct">
                DSP
                {dspLastMatchedDate && <span className="lastMatchedDate">(Zuletzt aktualisiert: {formatTimestamp(dspLastMatchedDate)})</span>}
              </div>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead className="tableHitResultsHeader">
                    <TableRow>
                      <TableCell className="hitResultRowCheckBox"></TableCell>
                      <TableCell className="hitResultRowStandard">Vorname</TableCell>
                      <TableCell className="hitResultRowStandard">Nachname</TableCell>
                      <TableCell className="hitResultRowSmaller">Geburtsdatum</TableCell>
                      <TableCell className="hitResultRowStandard">Anschrift</TableCell>
                      <TableCell className="hitResultRowStandard">IDs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dspRowsToDisplay.map((hit) =>
                      hit.bdsgBlocked ? (
                        // Display row as blocked if BDSG is active
                        <TableRow className="hitGesperrt" key={hit.id}>
                          <TableCell colSpan={8}>Anzeige gesperrt durch BDSG</TableCell>
                        </TableRow>
                      ) : (
                        // Render each selectable DSP row
                        <Tooltip title={<div className="toolTipText">Sie können die Auswahl nicht mehr aufheben, da das Dokument bereits erstellt wurde</div>} arrow placement="left" disableHoverListener={!disableDSPSelection} key={hit.id}>
                          <TableRow>
                            <TableCell className="hitResultRowCheckBox">
                              <Checkbox checked={selectedDSPRows.some((selectedRow) => selectedRow.id === hit.id) || hit.isSelected} onChange={(event) => handleRowSelect(event, hit, "DSP")} disabled={disableDSPSelection} />
                            </TableCell>
                            <TableCell className="hitResultRowStandard">{hit.firstName}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.lastName}</TableCell>
                            <TableCell className="hitResultRowSmaller">{parseDateFromInput(hit.birthDate)}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.street} {hit.houseNo}<br/>{hit.zipCode} {hit.city}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.id?`Id: ${hit.id}`:""}<br/>{hit.referenceNo?`Ref.: ${hit.referenceNo}`:""}</TableCell>
                          </TableRow>
                        </Tooltip>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          {/* Display CSP search results if available */}
          {cspRowsToDisplay.length > 0 && (
            <div className="hitResultsCSP">
              <div className="hlHitProduct">
                CSP
                {cspLastMatchedDate && <span className="lastMatchedDate">(Zuletzt aktualisiert: {formatTimestamp(cspLastMatchedDate)})</span>}
              </div>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead className="tableHitResultsHeader">
                    <TableRow>
                      <TableCell className="hitResultRowCheckBox"></TableCell>
                      <TableCell className="hitResultRowStandard">Vorname</TableCell>
                      <TableCell className="hitResultRowStandard">Nachname</TableCell>
                      <TableCell className="hitResultRowSmaller">Geburtsdatum</TableCell>
                      <TableCell className="hitResultRowStandard">Anschrift</TableCell>
                      <TableCell className="hitResultRowStandard">IDs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cspRowsToDisplay.map((hit) =>
                      hit.bdsgBlocked ? (
                        // Display row as blocked if BDSG is active
                        <TableRow className="hitGesperrt" key={hit.id}>
                          <TableCell colSpan={8}>Anzeige gesperrt durch BDSG</TableCell>
                        </TableRow>
                      ) : (
                        // Render each selectable CSP row
                        <Tooltip title={<div className="toolTipText">Sie können die Auswahl nicht mehr aufheben, da das Dokument bereits erstellt wurde</div>} arrow placement="left" disableHoverListener={!disableCSPSelection} key={hit.id}>
                          <TableRow>
                            <TableCell className="hitResultRowCheckBox">
                              <Checkbox checked={selectedCSPRows.some((selectedRow) => selectedRow.id === hit.id) || hit.isSelected} onChange={(event) => handleRowSelect(event, hit, "CSP")} disabled={disableCSPSelection} />
                            </TableCell>
                            <TableCell className="hitResultRowStandard">{hit.firstName}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.lastName}</TableCell>
                            <TableCell className="hitResultRowSmaller">{parseDateFromInput(hit.birthDate)}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.street} {hit.houseNo}<br/>{hit.zipCode} {hit.city}</TableCell>
                            <TableCell className="hitResultRowStandard">{hit.cspMainId?`MainId: ${hit.cspMainId}`:""}<br/>{hit.cspLiableId?`\nLiableId: ${hit.cspLiableId}`:""}</TableCell>
                          </TableRow>
                        </Tooltip>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          {/* Display CSP Product calls if available */}
          {cspProductCalls.length > 0 && (
              <div className="productTable">
                <div className="hlHitProduct">
                  CSP - Produkt Aufrufe
                </div>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead className="tableHitResultsHeader">
                      <TableRow>
                        <TableCell className="hitResultRowCheckBox"></TableCell>
                        <TableCell className="hitResultRowStandard">Archive Id</TableCell>
                        <TableCell className="hitResultRowStandard">Produkt Name</TableCell>
                        <TableCell className="hitResultRowSmaller">Datum</TableCell>
                        <TableCell className="hitResultRowStandard">Kunden Id</TableCell>
                        <TableCell className="hitResultRowStandard">Kunden Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cspProductCalls.map((product) => (
                                <TableRow>
                                  <TableCell className="hitResultRowCheckBox">
                                  </TableCell>
                                  <TableCell className="hitResultRowStandard">{product.archiveId}</TableCell>
                                  <TableCell className="hitResultRowStandard">{product.productName}</TableCell>
                                  <TableCell className="hitResultRowSmaller">{listToDate(product.requestTms)}</TableCell>
                                  <TableCell className="hitResultRowStandard">{product.custId}</TableCell>
                                  <TableCell className="hitResultRowStandard">{product.customerName}</TableCell>
                                </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
          )}
        </>
      )}
    </div>
  );
}

export default CaseDetailHitSelectionTableContainer;
