import axios from "axios";

const API_URL = "/auth/login";
const LOGOUT_URL = "/auth/logout";

axios.defaults.withCredentials = true; // Ensure cookies are sent with requests

// Axios request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      // Check if token is expired
      const tokenExpiry = parseInt(localStorage.getItem("tokenExpiry"), 10);
      if (tokenExpiry && tokenExpiry < new Date().getTime()) {
        // Token is expired, logout
        logout();
        return Promise.reject(new Error("Token expired. Logging out..."));
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expired or unauthorized
      console.log("Token expired or unauthorized. Logging out...");
      logout();
    }
    return Promise.reject(error);
  }
);

const login = async (username, password) => {
  try {
    const response = await axios.post(API_URL, { username, password });
    if (response.data.token && response.data.expiresIn) {
      const expiryTime = new Date().getTime() + response.data.expiresIn;
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("tokenExpiry", expiryTime);
      console.log("Login successful. Token expiry set to:", new Date(expiryTime));
    } else {
      throw new Error("Invalid response from server");
    }
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw new Error("Login failed");
  }
};

const logout = () => {
  try {
    axios.get(LOGOUT_URL, { withCredentials: true }).catch((error) => {
      console.error("Logout error:", error);
    });
  } catch (error) {
    console.error("Logout error:", error);
  } finally {
    localStorage.removeItem("user");
    localStorage.removeItem("tokenExpiry");
    console.log("Logged out and cleared token expiry");
  }
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const isTokenExpired = () => {
  const tokenExpiry = parseInt(localStorage.getItem("tokenExpiry"), 10);
  return tokenExpiry && tokenExpiry < new Date().getTime();
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
  isTokenExpired,
};

export default AuthService;
