import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {Alert, IconButton, LinearProgress, Snackbar, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import "../../styles/styleCaseDetailMenuContainer.css";
import axios from "axios";
import {saveAs} from "file-saver";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function CaseDetailMenuContainer({
                                     selectedStatus,
                                     setSelectedStatus,
                                     eingangsDatum,
                                     setEingangsDatum,
                                     dueDate,
                                     setDueDate,
                                     selectedCategory,
                                     setSelectedCategory,
                                     selectedLabels,
                                     setSelectedLabels,
                                     selectedAssignee,
                                     setSelectedAssignee,
                                     selectedRows,
                                     rowData,
                                     rowsPerPage,
                                     currentPage,
                                     sortOptionAscending,
                                     dspLastMatchedDate,
                                     tipLastMatchedDate,
                                     cspLastMatchedDate,
                                     dspReportCreationDate,
                                     tipReportCreationDate,
                                     cspReportCreationDate,
                                     onTriggerNotify,
                                     statusOptions,
                                     categoryOptions,
                                     labelOptions,
                                     activeAssigneeOptions
                                 }) {
    const navigate = useNavigate();

    // State for handling progress and uploading status
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [progressMessage, setProgressMessage] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");

    let lastGroup= 1;

    // handle changes to the values
    const handleCaseDetailsStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleEingangsDatumChange = (newDate) => {
        setEingangsDatum(newDate?.format("DD.MM.YYYY") || null); // Format as DD.MM.YYYY
    };

    const handleDueDateChange = (newDate) => {
        setDueDate(newDate?.format("DD.MM.YYYY") || null); // Format as DD.MM.YYYY
    };

    const handleCaseDetailsCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleCaseDetailsLabelChange = (event) => {
        const selectedIds = event.target.value;
        const newSelectedLabels = labelOptions.filter((option) => selectedIds.includes(option.id));
        setSelectedLabels(newSelectedLabels);
    };

    const handleCaseDetailsAssigneeChange = (event) => {
        setSelectedAssignee(event.target.value);
    };

    // Fetch and clear request body from the backend
    const handleBackClickEvent = async () => {
        try {
            // Call API to fetch and clear the saved request body
            const response = await axios.get(`/overview/getAndClearFilter/`);
            const filter = response.data;

            // Pass the entire request body if available
            navigate("/overviewTable", {
                state: {
                    rowsPerPage,
                    currentPage,
                    sortOptionAscending,
                    filter: filter || null, // Use filter directly if it exists
                },
            });
        } catch (error) {
            console.error("Error fetching or clearing request body:", error);
            navigate("/overviewTable", {
                state: {rowsPerPage, currentPage, sortOptionAscending}, // Navigate without request body on error
            });
        }
    };

    // Get the ids of the selected labels for the Select component
    const selectedLabelIds = selectedLabels.map((label) => label.id);

    // Funktion to generate and Id Request PDF in backend
    const createIdRequest = async () => {
        try {

            setProgressMessage("Dokument wird generiert..."); // Set progress message in German
            setUploading(true); // Show the progress bar

            const response = await axios.get(`/case/${rowData.id}/identreq`, {
                headers: {
                    withCredentials: true,
                },
                responseType: "blob", // Ensure response is treated as blob
                onDownloadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    if (total > 0) {
                        setUploadProgress(Math.round((loaded / total) * 100));
                    }
                },
            });

            setUploadProgress(100);
            setUploading(false); // Hide the progress bar after the PDF is generated
            const blob = new Blob([response.data], {type: "application/octet-stream"});
            // Determine the filename from the Content-Disposition header or use a default name
            const disposition = response.headers["content-disposition"];
            let filename = "downloaded_file";

            if (disposition && disposition.includes("filename=")) {
                const filenameMatch = disposition.match(/filename="?([^"]+)"?/);
                if (filenameMatch) {
                    // Sanitize the filename to avoid potential XSS issues
                    filename = filenameMatch[1].replace(/[^a-zA-Z0-9 ._-]/g, "_");
                }
            }
            //const blob = new File([response.data], response.headers.getContentType(), { type: "application/pdf"});
            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);
            // Directly trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Revoke the Blob URL after the download is initiated
            window.URL.revokeObjectURL(url);
            // now trigger the documents section
            onTriggerNotify();
        } catch (error) {
            console.error("Error fetching PDF: ", error);
            setUploading(false); // Hide the progress bar in case of error
        }
    };
    // Function to generate PDF from backend
    const generatePdf = async () => {
        try {
            const reqPerPlatform = [];

            if (selectedRows.DSP && selectedRows.DSP.length > 0) {
                selectedRows.DSP.forEach((row) => {
                    reqPerPlatform.push({
                        platform: "DSP",
                        selectedIdentifier: row.id,
                        refresh: dspLastMatchedDate > dspReportCreationDate,
                    });
                });
            }

            if (selectedRows.TIP && selectedRows.TIP.length > 0) {
                selectedRows.TIP.forEach((row) => {
                    reqPerPlatform.push({
                        platform: "TIP",
                        selectedIdentifier: row.id,
                        refresh: tipLastMatchedDate > tipReportCreationDate,
                    });
                });
            }

            if (selectedRows.CSP && selectedRows.CSP.length > 0) {
                selectedRows.CSP.forEach((row) => {
                    reqPerPlatform.push({
                        platform: "CSP",
                        selectedIdentifier: row.id,
                        refresh: cspLastMatchedDate > cspReportCreationDate,
                    });
                });
            }

            const jsonParams = {
                docFormat: "PDF",
                reqPerPlatform: reqPerPlatform,
            };

            const encodedParams = new URLSearchParams({
                genRequest: JSON.stringify(jsonParams),
            }).toString();

            setProgressMessage("PDF wird generiert..."); // Set progress message in German
            setUploading(true); // Show the progress bar

            const response = await axios.get(`/case/${rowData.id}/generate?${encodedParams}`, {
                headers: {
                    withCredentials: true,
                },
                responseType: "blob", // Ensure response is treated as blob
                onDownloadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    if (total > 0) {
                        setUploadProgress(Math.round((loaded / total) * 100));
                    }
                },
            });

            const blob = new Blob([response.data], {type: "application/pdf"});
            //const blob = new File([response.data], response.headers.getContentType(), { type: "application/pdf"});
            const url = window.URL.createObjectURL(blob);
            window.open(url); // Open PDF in a new tab
            setUploadProgress(100);
            setUploading(false); // Hide the progress bar after the PDF is generated
            setAlertOpen(true);
            setAlertMessage("Bericht erfolgreich erstellt");
            setAlertSeverity("success");
            // now trigger the documents section
            onTriggerNotify();
        } catch (error) {
            console.error("Error fetching PDF: ", error);
            await handleErrorResponse(error);
            setUploading(false); // Hide the progress bar in case of error
        }
    };

    async function handleErrorResponse(error) {
        setAlertSeverity("error");
        if (error.response.status === 500) {
            const text = await error.response.data.text();
            const json = JSON.parse(text);
            setAlertOpen(true);
            setAlertMessage(`${json.platform}, Serverseitiges Problem ist aufgetreten`);
        } else if (error.response.status === 400) {
            setAlertOpen(true);
            setAlertMessage(`Clientseitiges Problem ist aufgetreten`);
        }
    }

    // Function to generate Word document from backend
    const generateWord = async () => {
        try {
            const reqPerPlatform = [];

            if (selectedRows.DSP && selectedRows.DSP.length > 0) {
                selectedRows.DSP.forEach((row) => {
                    reqPerPlatform.push({
                        platform: "DSP",
                        selectedIdentifier: row.id,
                        refresh: dspLastMatchedDate > dspReportCreationDate,
                    });
                });
            }

            if (selectedRows.TIP && selectedRows.TIP.length > 0) {
                selectedRows.TIP.forEach((row) => {
                    reqPerPlatform.push({
                        platform: "TIP",
                        selectedIdentifier: row.id,
                        refresh: tipLastMatchedDate > tipReportCreationDate,
                    });
                });
            }

            if (selectedRows.CSP && selectedRows.CSP.length > 0) {
                selectedRows.CSP.forEach((row) => {
                    reqPerPlatform.push({
                        platform: "CSP",
                        selectedIdentifier: row.id,
                        refresh: cspLastMatchedDate > cspReportCreationDate,
                    });
                });
            }

            const jsonParams = {
                docFormat: "RTF",
                reqPerPlatform: reqPerPlatform,
            };

            const encodedParams = new URLSearchParams({
                genRequest: JSON.stringify(jsonParams),
            }).toString();

            setProgressMessage("RTF wird generiert..."); // Set progress message in German
            setUploading(true); // Show the progress bar

            const response = await axios.get(`/case/${rowData.id}/generate?${encodedParams}`, {
                headers: {
                    withCredentials: true,
                },
                responseType: "blob", // Ensure response is treated as blob
                onDownloadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    if (total > 0) {
                        setUploadProgress(Math.round((loaded / total) * 100));
                    }
                },
            });

            // Determine the filename from the Content-Disposition header or use a default name
            const disposition = response.headers["content-disposition"];
            let filename = "downloaded_file";

            if (disposition && disposition.includes("filename=")) {
                const filenameMatch = disposition.match(/filename="?([^"]+)"?/);
                if (filenameMatch) {
                    // Sanitize the filename to avoid potential XSS issues
                    filename = filenameMatch[1].replace(/[^a-zA-Z0-9 ._-]/g, "_");
                }
            }

            const blob = new Blob([response.data], {type: "application/rtf"});
            saveAs(blob, filename); // Save the document using FileSaver.js
            setUploadProgress(100);
            setUploading(false); // Hide the progress bar after the RTF is generated
            setAlertOpen(true);
            setAlertMessage("Bericht erfolgreich erstellt");
            setAlertSeverity("success");
            // now trigger the documents section
            onTriggerNotify();
        } catch (error) {
            console.error("Error fetching Word document: ", error);
            await handleErrorResponse(error);
            setUploading(false); // Hide the progress bar in case of error
        }
    };

    return (
        <div className="divCDMenuContainer">
            <div className="buttonElements">
                <IconButton className="cmdBackToOverview" onClick={handleBackClickEvent}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <div className="actionElements">
                <FormControl variant="standard" className="formControls" size="small">
                    <InputLabel id="tfSelect">Status</InputLabel>
                    <Select labelId="tfSelect" value={selectedStatus} onChange={handleCaseDetailsStatusChange}>
                        <MenuItem id="selectItems" value="Bitte Status auswählen" disabled>
                            <em>Bitte Status auswählen</em>{" "}
                        </MenuItem>
                        {statusOptions.map((caseDetailsStatusOption) => (
                            <MenuItem key={caseDetailsStatusOption.id} value={caseDetailsStatusOption.id}>
                                {caseDetailsStatusOption.text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className="formControls noBorders"
                        label="Eingangsdatum"
                        value={eingangsDatum ? dayjs(eingangsDatum, ["DD.MM.YYYY", "YYYY-MM-DD"]) : dayjs()}
                        onChange={handleEingangsDatumChange}
                        format="DD.MM.YYYY"
                        slotProps={{
                            textField: {variant: "standard", size: "small"}, // Use standard for underline
                        }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className="formControls noBorders"
                        label="Fälligkeitsdatum"
                        value={dueDate ? dayjs(dueDate, ["DD.MM.YYYY", "YYYY-MM-DD"]) : null}
                        onChange={handleDueDateChange}
                        format="DD.MM.YYYY"
                        slotProps={{
                            textField: {variant: "standard", size: "small"}, // Use standard for underline
                        }}
                    />
                </LocalizationProvider>
                <FormControl variant="standard" className="formControls" size="small">
                    <InputLabel id="tfSelect">Kategorie</InputLabel>
                    <Select labelId="tfSelect" value={selectedCategory} onChange={handleCaseDetailsCategoryChange}>
                        <MenuItem id="selectItems" value="Bitte Kategorie auswählen" disabled>
                            <em>Bitte Kategorie auswählen</em>{" "}
                        </MenuItem>
                        {categoryOptions.map((caseDetailsCategoryOption) => (
                            <MenuItem key={caseDetailsCategoryOption.id} value={caseDetailsCategoryOption.id}>
                                {caseDetailsCategoryOption.text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="standard" className="formControls" size="small">
                    <InputLabel id="tfSelect">Labels</InputLabel>
                    <Select multiple labelId="tfSelect" value={selectedLabelIds}
                            onChange={handleCaseDetailsLabelChange}>
                        <MenuItem id="selectItems" value="Bitte Label auswählen" disabled>
                            <em>Bitte Label auswählen</em>{" "}
                        </MenuItem>
                        {
                            labelOptions.filter(l => l.order < 90).map(actionLabelOption => {
                                let returnValue =
                                    <MenuItem id="acSelectBoxItems" key={actionLabelOption.id} value={actionLabelOption.id} style={lastGroup === actionLabelOption.order?{}:{borderTop:".5px solid lightgray"}}>
                                        {actionLabelOption.text}
                                    </MenuItem>
                                lastGroup =actionLabelOption.order;
                                return returnValue;
                                }
                            )
                        }
                    </Select>
                </FormControl>
                <FormControl variant="standard" className="formControls" size="small">
                    <InputLabel id="tfSelect">Assignee</InputLabel>
                    <Select labelId="tfSelect"
                            value={activeAssigneeOptions.find(e => e.id === selectedAssignee) === undefined ? "" : selectedAssignee}
                            onChange={handleCaseDetailsAssigneeChange}>
                        <MenuItem id="selectItems" value="Bitte Assignee auswählen" disabled>
                            <em>Bitte Assignee auswählen</em>{" "}
                        </MenuItem>
                        {activeAssigneeOptions.map((caseDetailsAssigneeOption) => (
                            <MenuItem key={caseDetailsAssigneeOption.id} value={caseDetailsAssigneeOption.id}>
                                {caseDetailsAssigneeOption.firstName} {caseDetailsAssigneeOption.lastName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className="reportElements">
                {
                    selectedStatus !== 507 && <IconButton className="cmdCreateIdRequest" onClick={createIdRequest}>
                        <BadgeOutlinedIcon/>
                    </IconButton>
                }
                {
                    selectedStatus !== 507 && <IconButton className="cmdCreatePDFReport" onClick={generatePdf}>
                        <PictureAsPdfOutlinedIcon/>
                    </IconButton>
                }
                {
                    selectedStatus !== 507 && <IconButton className="cmdCreateRTFReport" onClick={generateWord}>
                        <InsertDriveFileOutlinedIcon/>
                    </IconButton>
                }
            </div>

            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
            >
                <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            {/* Progress container */}
            {uploading && (
                <div className="excelLoadingContainer">
                    <div className="excelProgressBarWrapper">
                        <LinearProgress className="excelProgressBar" variant="determinate" value={uploadProgress}/>
                    </div>
                    <Typography className="excelProgressText">
                        {progressMessage} {Math.round(uploadProgress)}%
                    </Typography>
                </div>
            )}
        </div>
    );
}

export default CaseDetailMenuContainer;
