import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/styleCaseOverview.css";
import "./styles/styleCaseDetails.css";
import CaseDetails from "./components/caseDetails/CaseDetails";
import CaseWorkerLogin from "./components/loginForm/CaseWorkerLogin";
import Overview from "./components/overview/Overview";
import OnlineExternalForm from "./components/onlineForm/OnlineForm";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./authServices/AuthContext";
import ProtectedRoute from "./authServices/ProtectedRoute";
import {CustomHookProvider} from "./components/customHooks/useCustomHookContext";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check the current hostname and redirect if it matches specified domains
    const host = window.location.hostname;
    if (host === "int-test-selbstauskunft.crif.de" || host === "selbstauskunft.crif.de") {
      navigate("/onlineForm");
    }
  }, [navigate]);

  return (
      <div className="App">
        <AuthProvider>
          <CustomHookProvider>
          <Routes>
            <Route path="/" element={<CaseWorkerLogin />} />
            <Route path="/onlineForm" element={<OnlineExternalForm />} />
            <Route path="/overviewTable" element={<ProtectedRoute element={<Overview />} />} />
            <Route path="/caseDetails" element={<ProtectedRoute element={<CaseDetails />} />} />
          </Routes>
          <div className="Title"></div>
          </CustomHookProvider>
        </AuthProvider>
      </div>
  );
}

export default App;
