
import React, {createContext, useContext} from "react";
import useGetCaseAttribute from "./useGetCaseAttributes";
import useGetCaseWorkers from "./useGetCaseWorkers";

// create Context
const CustomHookContext = createContext(null);

// Provider Component
export const CustomHookProvider = ({children}) => {
    const codes = useGetCaseAttribute();
    const caseWorkers = useGetCaseWorkers();

    return (
        <CustomHookContext.Provider value={{...codes, ...caseWorkers}}>
            {children}
        </CustomHookContext.Provider>
    )
}

// Custom Hook Consumer
export const useCustomHookContext = () => {
    const context = useContext(CustomHookContext);
    if (!context) {
        throw new Error("useCustomHookContext must be used within a CustomHookProvider");
    }
    return context;
}