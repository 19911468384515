import { useState, useEffect } from "react";
import axios from "axios";

const useGetCaseWorkers = () => {
  const [activeAssigneeOptions, setActiveAssigneeOptions] = useState([]);
  const [allUserOptions, setAllUserOptions] = useState([]);

  const getCaseWorkers = async () => {
    try {
      const response = await axios.get("/list/users", {
        headers: {
          withCredentials: true,
        },
      });
      const allUser = response.data;
      const activeUser = allUser.filter(user=>user.activeUser);
      setActiveAssigneeOptions(activeUser);
      setAllUserOptions(allUser);
      // setCaseDetailsAssigneeOptions(activeUser);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const user = localStorage.getItem("user");

  useEffect(() => {
    if (user){
      getCaseWorkers();
    }

  }, [user]);

  return { activeAssigneeOptions, allUserOptions };
};

export default useGetCaseWorkers;
