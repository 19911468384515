import React, { useState, useEffect, useRef, useCallback } from "react";
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, Button, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import axios from "axios";
import "../../styles/styleCaseDetailDocumentContainer.css";

function CaseDetailDocumentContainer({ rowData, triggerDocumentsSection, uploadedFiles, setUploadedFiles, contentTypeOptions, contentTypes }) {

  // State for handling confirmation dialog for deletion
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // State for handling snackbar notifications
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // State for storing the ID of the selected file to delete
  const [selectedFileId, setSelectedFileId] = useState(null);

  // State for handling the "file already exists" dialog
  const [fileExists, setFileExists] = useState(false);

  // State for handling the file size error dialog
  const [fileSizeError, setFileSizeError] = useState(false);

  // State for handling the content type error dialog
  const [contentTypeError, setContentTypeError] = useState({
    open: false,
    message: "",
  });

  // State for storing the selected content type
  const [selectedContentType, setSelectedContentType] = useState("");

  // Ref for accessing the file input element
  const fileInputRef = useRef(null);

  // Function to fetch case details from API
  const getCaseDetails = useCallback(async () => {
    try {
      if (!rowData || !rowData.id) return;

      const response = await axios.get(`/case/${rowData.id}`, {
        headers: { withCredentials: true },
      });

      // Set the initial list of uploaded files
      const initialFiles =
        response.data.caseBlobs?.map((blob) => ({
          id: blob.id,
          filename: blob.filename,
          documentTypeCode: blob.documentTypeCode,
        })) || [];
      setUploadedFiles(initialFiles);
    } catch (error) {
      console.error("Error fetching case details:", error);
    }
  }, [rowData]);

  // Fetch case details when rowData changes
  useEffect(() => {
    if (triggerDocumentsSection){
      getCaseDetails();
    }
  }, [triggerDocumentsSection]);

  // Event handler for file selection and upload
  const handleFileChange = async (event) => {
    // Convert the FileList to an array for easier processing
    const files = Array.from(event.target.files);

    // Exit early if no files are selected or no document type is chosen
    if (files.length === 0 || !selectedContentType) return;

    // Filter files for validations: size limit (10MB) and duplicates
    const validFiles = files.filter((file) => {
      if (file.size > 10 * 1024 * 1024) {
        setFileSizeError(true); // Show an error message for oversized files
        // Reset file input value to allow re-selection
        event.target.value = null;
        return false;
      }
      if (uploadedFiles.some((uploadedFile) => uploadedFile.filename === file.name)) {
        setFileExists(true); // Show an error for duplicate files
        // Reset file input value to allow re-selection
        event.target.value = null;
        return false;
      }
      return true; // Only valid files pass through
    });

    // If no valid files remain, exit
    if (validFiles.length === 0) return;

    // Create a FormData object and append each valid file
    const formData = new FormData();
    validFiles.forEach((file) => formData.append("files", file));

    try {
      // Send the files to the server via POST request
      const response = await axios.post(
          `/case/${rowData.id}/${parseInt(selectedContentType)}/upload`, // API endpoint
          formData,
          {
            headers: {
              withCredentials: true, // Include cookies in the request
              "Content-Type": "multipart/form-data", // Set the proper content type for file uploads
            },
          }
      );

      if (response.status === 200) {
        // If upload is successful, refresh the case details
        getCaseDetails();
      }
    } catch (error) {
      console.error("Error uploading files:", error);

      if (error.response?.status === 400) {
        // Retrieve the text for the selected document type
        const selectedContentTypeText =
            contentTypeOptions.find((option) => option.id === selectedContentType)
                ?.text || "unbekannter Typ";

        // Show a specific error if the content type already exists
        setContentTypeError({
          open: true,
          message: `Dokument mit ausgewähltem Inhalt '${selectedContentTypeText}' ist bereits vorhanden`,
        });
      }
    } finally {
      // Reset file input to allow new file selection
      event.target.value = null;

      // Reset the selected document type to default after the upload
      setSelectedContentType("");
    }
  };

  // Handle file deletion
  const handleDeleteFile = async () => {
    try {
      await axios.delete(`/blob/${selectedFileId}`, {
        headers: { withCredentials: true },
      });

      // Remove the file from the state without refreshing
      setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== selectedFileId));

      setOpenConfirmDialog(false);
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // Open confirmation dialog for file deletion
  const handleConfirmDelete = (fileId) => {
    setSelectedFileId(fileId);
    setOpenConfirmDialog(true);
  };

  // Trigger file input click
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Close snackbar notification
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Close "file already exists" dialog
  const handleCloseFileExistsDialog = () => {
    setFileExists(false);
  };

  // Close file size error dialog
  const handleCloseFileSizeErrorDialog = () => {
    setFileSizeError(false);
  };

  // Handle closing the content type error dialog
  const handleCloseContentTypeErrorDialog = () => {
    setContentTypeError((prevState) => ({
      ...prevState,
      open: false,
    }));

    // Ensure state change is applied immediately
    setTimeout(() => {
      setContentTypeError((prevState) => ({
        ...prevState,
        message: "",
      }));
    }, 0);
  };

// Define allowed MIME types
  const allowedMimeTypes = [
    "application/pdf",
    "application/zip",
    "application/json",
    "image/jpeg",
    "image/png",
    "application/msword",
    "application/rtf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-outlook",
    "image/gif",
    "application/octet-stream"
  ];

// Handle file click
  const handleFileClick = async (fileId) => {
    try {
      // Fetch the file as a Blob from the backend
      const response = await axios.get(`/blob/${fileId}`, {
        responseType: "blob",
        headers: { withCredentials: true },
      });

      // Determine the MIME type from the response headers or default to a generic type
      const contentType = response.headers["content-type"] || "application/octet-stream";

      // Validate the MIME type against allowed types
      if (!allowedMimeTypes.includes(contentType)) {
        throw new Error("Unsupported file type");
      }

      // Determine the filename from the Content-Disposition header or use a default name
      const disposition = response.headers["content-disposition"];
      let filename = "downloaded_file";

      if (disposition && disposition.includes("filename=")) {
        const filenameMatch = disposition.match(/filename="?([^"]+)"?/);
        if (filenameMatch) {
          // Sanitize the filename to avoid potential XSS issues
          filename = filenameMatch[1].replace(/[^a-zA-Z0-9 ._-]/g, "_");
        }
      }

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: contentType });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Directly trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Revoke the Blob URL after the download is initiated
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching file content:", error);
    }
  };


  // Function to get the text description for the documentTypeCode
  const getDocumentTypeText = (code) => {
    const option = contentTypes.find((opt) => opt.id === code);
    return option ? option.text : "Unbekannter Typ";
  };

  return (
      <div className="divCDDocumentContainer">
        <div className="hlDocumentUploader">Dokumente</div>
        <div className="scrollableListContainer">
          <List>
            {uploadedFiles.length > 0 ? (
                uploadedFiles.map((doc) => (
                    <ListItem key={doc.id} className="documentList">
                      <ListItemIcon className="documentListItem">
                        <InsertDriveFileOutlinedIcon/>
                      </ListItemIcon>
                      <ListItemText
                          primary={`${doc.filename} (${getDocumentTypeText(doc.documentTypeCode)})`}
                          onClick={() => handleFileClick(doc.id)} // Handle file click to open in new tab
                          className="clickableFile" // Add a class for styling the clickable item if needed
                      />
                      <IconButton className="documentListDeleteButton" onClick={() => handleConfirmDelete(doc.id)}>
                        <ClearOutlinedIcon/>
                      </IconButton>
                    </ListItem>
                ))
            ) : (
                <ListItem>
                  <ListItemText primary="Keine Dokumente vorhanden"/>
                </ListItem>
            )}
          </List>
        </div>

          <input type="file" className="inputFieldFileUpload" ref={fileInputRef} onChange={handleFileChange} multiple/>
          <div className="fileUploadContainer">
            <FormControl variant="standard" size="small" className="docFormControls">
              <InputLabel id="selectItems">Dokument Typ</InputLabel>
              <Select labelId="selectItems" value={selectedContentType} onChange={(e) => setSelectedContentType(e.target.value)}>
                <MenuItem id="selectItemsContentType" value="">
                  <em>Bitte Dokument Typ auswählen</em>
                </MenuItem>
                {contentTypeOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.text}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button className="cmdAddDocument" size="small" startIcon={<FileUploadOutlinedIcon/>} onClick={handleButtonClick}>
              Dokument hinzufügen
            </Button>
            <br/>
            <span className="fileSizeNote">(Notiz: Maximale Dateigröße 10MB)</span>
          </div>

          {/* Confirmation dialog for file deletion */}
          <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
            <DialogTitle>Löschen</DialogTitle>
            <DialogContent>
              <DialogContentText>Möchten Sie wirklich die Datei löschen?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                Abbrechen
              </Button>
              <Button onClick={handleDeleteFile} color="primary">
                Löschen
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for "file already exists" alert */}
          <Dialog open={fileExists} onClose={handleCloseFileExistsDialog}>
            <DialogTitle>Datei bereits vorhanden</DialogTitle>
            <DialogContent>
              <DialogContentText>Diese Datei wurde bereits hochgeladen</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFileExistsDialog} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for file size error */}
          <Dialog open={fileSizeError} onClose={handleCloseFileSizeErrorDialog}>
            <DialogTitle>Dateigröße überschritten</DialogTitle>
            <DialogContent>
              <DialogContentText>Die Dateigröße überschreitet 10 MB</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFileSizeErrorDialog} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for content type error */}
          <Dialog open={contentTypeError.open} onClose={handleCloseContentTypeErrorDialog}>
            <DialogTitle>Fehler</DialogTitle>
            <DialogContent>
              <DialogContentText>{contentTypeError.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseContentTypeErrorDialog} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>

          {/* Snackbar for file deletion success */}
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="success">
              Datei erfolgreich gelöscht!
            </Alert>
          </Snackbar>
        </div>
        );
        }

        export default CaseDetailDocumentContainer;
