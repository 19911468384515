import React, { useState } from "react";
import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import "../../styles/styleCaseDetailHitSelectionContainer.css";
import {Warning} from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";

function CaseDetailHitSelectionContainer({ selectedItems, onSelectionChange, onRefresh, vorgangsnummer, dspCommunicationError, tipCommunicationError, cspCommunicationError }) {
  const [openDialog, setOpenDialog] = useState(false);

  // Open the confirmation dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Close the confirmation dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Confirm the refresh action
  const handleConfirmRefresh = () => {
    onRefresh(selectedItems); // Pass the selectedItems state to the onRefresh function
    handleCloseDialog();
  };

  // Toggle selection state with exclusivity for DSP and Unscharfesuchen
  const handleToggle = (item) => (event) => {
    const isChecked = event.target.checked;

    // Handle mutual exclusivity between DSP and Unscharfesuchen
    if (item === "dsp" && isChecked) {
      // If DSP is checked, uncheck Unscharfesuchen
      onSelectionChange("dsp", true);
      onSelectionChange("unscharfeSuchenDsp", false);
    } else if (item === "unscharfeSuchenDsp" && isChecked) {
      // If Unscharfesuchen is checked, uncheck DSP
      onSelectionChange("unscharfeSuchenDsp", true);
      onSelectionChange("dsp", false);
    } else {
      // For other items, just toggle the checkbox normally
      onSelectionChange(item, isChecked);
    }
  };

  return (
      <div className="caseDetailHitSelectionContainer">
        <div className="hlProductHitList">Datenquelle auswählen</div>
        <List className="productHitList">
          <ListItem className="productHitListItem">
            <ListItemButton dense>
              <ListItemIcon>
                <Checkbox edge="start" tabIndex={-1} disableRipple checked={selectedItems.csp} onChange={handleToggle("csp")} />
              </ListItemIcon>
              <ListItemText className="productHitListItemText" primary={`CSP`} />
              <ListItemIcon className="warningIcon">
                {cspCommunicationError ? <WarningIcon/> : null}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem className="productHitListItem">
            <ListItemButton dense>
              <ListItemIcon>
                <Checkbox edge="start" tabIndex={-1} disableRipple checked={selectedItems.tip} onChange={handleToggle("tip")} />
              </ListItemIcon>
              <ListItemText className="productHitListItemText" primary={`TIP`} />
              <ListItemIcon className="warningIcon">
                {tipCommunicationError ? <WarningIcon/> : null}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem className="productHitListItem">
            <ListItemButton dense>
              <ListItemIcon>
                <Checkbox edge="start" tabIndex={-1} disableRipple checked={selectedItems.dsp} onChange={handleToggle("dsp")} />
              </ListItemIcon>
              <ListItemText className="productHitListItemText" primary={`DSP`} />
              <ListItemIcon className="warningIcon">
                {dspCommunicationError ? <WarningIcon/> : null}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem className="productHitListItem">
            <ListItemButton dense>
              <ListItemIcon>
                <Checkbox edge="start" tabIndex={-1} disableRipple checked={selectedItems.unscharfeSuchenDsp} onChange={handleToggle("unscharfeSuchenDsp")} />
              </ListItemIcon>
              <ListItemText className="productHitListItemText" primary={`DSP (unscharfe Suche)`} />
              <ListItemIcon className="warningIcon">
                {dspCommunicationError ? <WarningIcon/> : null}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <Button className="anfrageAktualisieren" size="small" startIcon={<UpdateOutlinedIcon />} onClick={handleOpenDialog}>
          Anfrage aktualisieren
        </Button>

        <div className="vorgangsnummer">Vorgangsnummer: {vorgangsnummer}</div>

        {/* Confirmation Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Bestätigung</DialogTitle>
          <DialogContent>
            <DialogContentText>Möchten Sie eine neue Suche starten?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Abbrechen
            </Button>
            <Button onClick={handleConfirmRefresh} color="primary">
              Bestätigen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default CaseDetailHitSelectionContainer;
